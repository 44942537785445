import {
  START_FETCH_HOME_PAGE_DATA,
  SUCCESS_FETCH_HOME_PAGE_DATA,
  ERROR_FETCH_HOME_PAGE_DATA,
} from "./homePageActionsTypes";

import { fetcher } from "@/utils/functions";

import { getSelectedLangData } from "@/utils/functions";
import { fetchCMSDataQuery } from "@/services/requestService";

/**
 * This function used for getting data of Home Page
 * @param {*} requestParams
 */
export function fetchHomePagesData(requestParams, preview) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchHomePageDataStart());
      // const lang = getSelectedLangData();
      // requestParams = requestParams?.replace(/\lang/g, lang);

      fetchCMSDataQuery(requestParams, preview)
        .then((data) => {
          dispatch(fetchHomePageDataSuccess(data));
          resolve(data);
        })
        .catch((err) => {
          console.log("err", err);
          reject(err);
          dispatch(fetchHomePageDataFailure("A network error occured"));
        });
    });
  };
}

function fetchHomePageDataStart() {
  return {
    type: START_FETCH_HOME_PAGE_DATA,
  };
}

function fetchHomePageDataSuccess(homePageData) {
  return {
    type: SUCCESS_FETCH_HOME_PAGE_DATA,
    homePageData,
  };
}

function fetchHomePageDataFailure(error) {
  return {
    type: ERROR_FETCH_HOME_PAGE_DATA,
    error,
  };
}

export const setHomePageData = (homePageData) => {
  return {
    type: SUCCESS_FETCH_HOME_PAGE_DATA,
    homePageData,
  };
};
